const zhCN = {
    public: {
        name: {
            status: "状态",
            login: "登录",
            logout: "登出",
            hello: "你好",
            username: "用户名",
            account: "账号",
            email: "E-mail",
            password: "密码",
            confirm_password: "确认密码",
            reset_password: "重置密码",
            captcha: "验证码",
            more: "更多",
            add: "添加",
            edit: "编辑",
            del: "删除",
            detail: "详情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你确定需要删除该信息吗",
            action_cannot_resumed: "该操作将无法恢复",
            search: "搜索",
            reset: "重置",
            spread: "展开",
            retract: "收回",
            show: "展示",
            no_page: "无页面",
            service: "服务商",
            phone_country_code: "电话国家代码",
            name: "名称",
            phone: "电话",
            sex: "性别",
            country: "国家",
            address: "地址",
            birthday: "出生年月日",
            remark: "备注",
            personal: "个人信息",
            enclosure: "附件",
            logo: "Logo",
            confirm: "确认",
            send: "发送",
            export: "导出",
            no_permission: "没有权限",
            select_none: "不选",
            no_data: "没有数据！"
        },
        language: {
            zhTW: "中文(繁体)",
            enUS: "英文",
            zhCN: "中文(简体)"
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "请选择",
        },
        table: {
            name: "名称",
            status: "状态",
            action: "操作",
            content: "内容",
            created_at: "创建时间",
            image: "图像",
            email: "E-mail",
            order_id: "排序",
            sex: "性别",
            last_ip: "最后登录IP",
            phone: "电话",
        },
        field: {
            name: "名称",
            username: "用户名",
            content: "内容",
            phone_country_code: "电话国家代码",
            phone: "电话",
            email: "E-mail",
            order_id: "排序",
            portrait: "头像",
            status: "状态",
            image: "图像",
            sex: "性别",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "请输入用户名",
                password: "请输入密码",
                repassword: "请重复密码",
                name: "请输入名称",
                phone: "请输入电话",
                email: "请输入E-mail",
                portrait: "请上传头像",
                phone_country_code: "请选择电话国家代码",
            },
            tips: {
                inconsistent_password: "密码不一致",
                length_should: "长度应为{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁体）",
        enUS: "英语",
        zhCN: "中文（简体）"
    },
    nav: {
        name: {
            Dashboard: "仪錶盘",
            AnnualLeavePlanManage: "年假计划管理",
            AttendanceManage: "考勤管理",
            AttendanceLogsManage: "考勤记录管理",
            AuthorityManage: "权限管理",
            DepartmentManage: "部门管理",
            EmployeeManage: "员工管理",
            Settings: "系统设定",
            EmployeeEntryRecordManage: "员工入职时间管理",
            HolidayManage: "公众假期管理",
            LeaveManage: "请假管理",
            LeaveTypeManage: "请假类型管理",
            SelfLeaveManage: "请假",
            MpfSettingManage: "强积金设定管理",
            OvertimeApplicationManage: "加班申请管理",
            SelfOvertimeApplicationManage: "申请加班",
            RemainingVacationManage: "剩余假期管理",
            ReportManage: "薪金管理",
            RoleManage: "职位管理",
            SalaryInformationManage: "工资信息管理",
            SettingsManage: "系统设定管理",
            EmployeeModel: "员工",
            AttendanceModel: "考勤",
            ReportModel: "报告",
            RoleModel: "职位",
            SalaryModel: "薪金",
            CifManage: "公司和用户资料（CIF 和入职）",
            PavementManage: "路面管理",
            RoboticControManage: "机器人控制管理",
            RemoteOperationManage: "通过 5G 网络远程操作",
            ConeDispenseCollectManage: "锥形分配和收集",
            TunnelWashingManage: "隧道清洗",
            PlantsCroppingManage: "植物种植",
            RoadMarkingManage: "道路标记",
            TestingEquationManage: "测试方程/公式库",
            UtilityManage: "工具",
            MaterialCategoriesManage: "物料类别和中央仓库",
            MobileDeviceManage: "移动设备管理 (IoT)",
            ReportingManage: "报告管理",
            IdentityManage: "身份和访问管理 ",
            RoadStudManage: "路钉管理",
            NamiResearchedManage: "Nami研究数据",
            AnalyticsCombinationManage: "分析组合",
            AIBankManage: "人工智能 银行+人工智能 训练",
            TestingMaterialManage: "测试材料指标",
            TestingMachineManage: "试验机管理",
            LoraWanManage: "LoraWan 数据采集管理",
        },
        table: {
            order_id: "排序",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名称",
            url: "Url",
            status: "状态",
            icon: "图标",
        },
    },
    login: {
        validate: {
            required: {
                username: "请输入用户名",
                email: "请输入邮箱",
                password: "输输入密码",
                captcha: "请输入验证码",
            }
        }
    },
    annualLeavePlan:{
        name: {
            detailTitle: "AnnualLeavePlan Detail",
            editTitle: "Edit AnnualLeavePlan",
            search: "名称"
        },
        table: {
            id_ann_lea_pla: "ID",
            plan_name: "计划名称",
            annual_leave_days: "年假",
            maternity_leave_days: "产假",
            paternity_leave_days: "侍产假",
            annual_leave_days_year_imcrement: "年假年递增",
            max_annual_leave_days: "年假上限",
        },
        field: {
            id_ann_lea_pla: "ID",
            plan_name: "计划名称",
            annual_leave_days: "年假",
            maternity_leave_days: "产假",
            paternity_leave_days: "侍产假",
            annual_leave_days_year_imcrement: "年假年递增",
            max_annual_leave_days: "年假上限",
        },
        validate: {
            required: {
                id_ann_lea_pla: "请填写ID",
                plan_name: "请填写计划名称",
                annual_leave_days: "请填写年假",
                maternity_leave_days: "请填写产假",
                paternity_leave_days: "请填写侍产假",
                annual_leave_days_year_imcrement: "请填写年假年递增",
                max_annual_leave_days: "请填写年假上限",
            }
        },
    },
    attendance:{
        name: {
            detailTitle: "Attendance Detail",
            editTitle: "Edit Attendance",
            search: "名称",
            dateRange: "日期范围",
            department: "部门",
            select_all: "全部",
            late: "是否迟到",
            earlyLeave: "是否早退"
        },
        table: {
            id_att: "ID",
            id_emp: "员工",
            check_in: "上班时间",
            check_out: "下班时间",
            late: "迟到",
            early_leave: "早退",
        },
        field: {
            id_att: "ID",
            id_emp: "员工",
            check_in: "上班时间",
            check_out: "下班时间",
            late: "迟到",
            early_leave: "早退",
        },
        validate: {
            required: {
                id_att: "请填写ID",
                id_emp: "请填写员工",
                check_in: "请填写上班时间",
                check_out: "请填写下班时间",
                late: "请填写迟到",
                early_leave: "请填写早退",
            }
        },
    },
    attendanceLogs:{
        name: {
            detailTitle: "AttendanceLogs Detail",
            editTitle: "Edit AttendanceLogs"
        },
        table: {
            id_att_log: "ID",
            id_emp: "员工",
            check_time: "拍卡时间",
            verify_mode: "验证方式",
        },
        field: {
            id_att_log: "ID",
            id_emp: "员工",
            check_time: "拍卡时间",
            verify_mode: "验证方式",
        },
        validate: {
            required: {
                id_att_log: "请填写ID",
                id_emp: "请填写员工",
                check_time: "请填写拍卡时间",
                verify_mode: "请填写验证方式",
            }
        },
    },
    authority:{
        name: {
            detailTitle: "Authority Detail",
            editTitle: "Edit Authority"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
        },
        validate: {
            required: {
                id_aut: "请填写ID",
                a_id_aut: "请填写父权限",
                name: "请填写权限",
                resource_name: "请填写权限标识",
                type: "请填写权限类型",
            }
        },
    },
    department:{
        name: {
            detailTitle: "Department Detail",
            editTitle: "Edit Department",
            search: "名称"
        },
        table: {
            id_dep: "ID",
            name: "部门",
            approver_id: "第一审批人",
            approver2_id: "第二审批人",
        },
        field: {
            id_dep: "ID",
            name: "部门",
            approver_id: "第一审批人",
            approver2_id: "第二审批人",
        },
        validate: {
            required: {
                id_dep: "请填写ID",
                name: "请填写部门",
                approver_id: "请填写第一审批人",
                approver2_id: "请填写第二审批人",
            }
        },
    },
    employee:{
        name: {
            detailTitle: "Employee Detail",
            editTitle: "Edit Employee",
            quit: "离职",
            revokeQuit: "撤销离职",
            reinstated: "复职",
            search: "名称",
            are_you_sure_quit: "是否确定离职该员工",
            are_you_sure_revoke_quit: "是否确定撤销离职该员工",
            are_you_sure_reinstated: "是否确定复职该员工",
            status: "状态",
            status_all: "全部",
            status_be_on_the_job: "在职",
            status_quit: "离职",
            gccsSetUser: "写入考勤机",
            positionList: "历史职位记录",
            reset_password:"重置密码"
        },
        table: {
            id_emp: "ID",
            id_rol: "职位",
            id_ann_lea_pla: "年假计划",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            avatar_url: "头像",
            no: "员工编号",
            name_tw: "员工姓名（中文）",
            name_en: "员工姓名（英）",
            id_card: "身份证",
            mobile_number: "手机号",
            phone_number: "电话",
            email: "邮箱",
            address: "地址",
            gender: "性別",
            birthday: "生日",
            remark: "备注",
            status: "状态",
            duty_plan_type: "上班类型",
            emergency_contact: "紧急联系人",
            bank_account: "银行账号",
            nick_name: "昵称",
            marital_status: "婚姻状况",
            last_password_update_time: "上次密码更新时间",
            enroll_id: "考勤机ID",
        },
        field: {
            id_emp: "ID",
            id_rol: "职位",
            id_ann_lea_pla: "年假计划",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            avatar_url: "头像",
            no: "员工编号",
            name_tw: "员工姓名（中文）",
            name_en: "员工姓名（英）",
            id_card: "身份证",
            mobile_number: "手机号",
            phone_number: "电话",
            email: "邮箱",
            address: "地址",
            gender: "性別",
            birthday: "生日",
            remark: "备注",
            status: "状态",
            duty_plan_type: "上班类型",
            entry_date: "入职日期",
            emergency_contact: "紧急联系人",
            bank_account: "银行账号",
            nick_name: "昵称",
            marital_status: "婚姻状况",
            position:"职位",
            start_time:"开始时间",
            last_password_update_time: "上次密码更新时间",
            enroll_id: "考勤机ID",
            auth_token: "关联Token",
        },
        validate: {
            required: {
                id_emp: "请填写ID",
                id_rol: "请填写角色",
                id_ann_lea_pla: "请填写年假计划",
                account: "请填写账号",
                password: "长度至少为8，至少含有一个字母和一个数字和一个特殊字符",
                salt: "请填写盐",
                last_login_time: "请填写上次登录时间",
                register_time: "请填写创建时间",
                login_failure_counter: "请填写登录错误计数",
                avatar_url: "请填写头像",
                entry_date: "请填写入职日期",
                no: "请填写员工编号",
                name_tw: "请填写员工姓名（中文）",
                name_en: "请填写员工姓名（英）",
                id_card: "请填写身份证",
                mobile_number: "请填写手机号",
                phone_number: "请填写电话",
                email: "请填写邮箱",
                address: "请填写地址",
                gender: "请填写性別",
                birthday: "请填写生日",
                remark: "请填写备注",
                status: "请填写状态",
                duty_plan_type: "请填写上班类型",
                emergency_contact: "请填写紧急联系人",
                bank_account: "请填写银行账号",
                nick_name: "请填写昵称",
                marital_status: "请填写婚姻状况",
                last_password_update_time: "请填写上次密码更新时间",
                enroll_id: "请填写考勤机ID",
                auth_token: "请填写关联Token",
            }
        },
    },
    employeeEntryRecord:{
        name: {
            detailTitle: "EmployeeEntryRecord Detail",
            editTitle: "Edit EmployeeEntryRecord",
            search: "名称",
            new_join_report: "入职报告",
            term_report: "离职报告",
        },
        table: {
            id_emp_ent_rec: "ID",
            id_emp: "员工",
            entry_date: "员工入职时间",
            term_date: "员工离职时间",
            term_remark: "员工离职原因",
        },
        field: {
            id_emp_ent_rec: "ID",
            id_emp: "员工",
            entry_date: "员工入职时间",
            term_date: "员工离职时间",
            term_remark: "员工离职原因",
        },
        validate: {
            required: {
                id_emp_ent_rec: "请填写ID",
                id_emp: "请填写员工",
                entry_date: "请填写员工入职时间",
                term_date: "请填写员工离职时间",
                term_remark: "请填写员工离职原因",
            }
        },
    },
    holiday:{
        name: {
            detailTitle: "Holiday Detail",
            editTitle: "Edit Holiday",
            search: "名称"
        },
        table: {
            id_hol: "ID",
            holiday_name: "假期名称",
            start_date: "开始时间",
            end_date: "结束时间",
            remark: "备注",
        },
        field: {
            id_hol: "ID",
            holiday_name: "假期名称",
            start_date: "开始时间",
            end_date: "结束时间",
            remark: "备注",
        },
        validate: {
            required: {
                id_hol: "请填写ID",
                holiday_name: "请填写假期名称",
                start_date: "请填写开始时间",
                end_date: "请填写结束时间",
                remark: "请填写备注",
            }
        },
    },
    leave:{
        name: {
            detailTitle: "Leave Detail",
            editTitle: "Edit Leave",
            approve: "审批",
            search: "名称",
            are_you_sure_send: "是否确认要发送该请假申请",
            not_enough_annual_leave_days: "年假不足!",
            not_enough_maternity_leave_days: "产假不足!",
            not_enough_paternity_leave_days: "侍产假不足!",
            leave_report: "请假报告",
        },
        table: {
            id_lea: "ID",
            id_emp: "员工",
            id_lea_typ: "请假类型",
            leave_type: "请假类型",
            start_time: "开始时间",
            end_time: "结束时间",
            duration: "时长（天）",
            reason: "原因",
            picture_url: "图片路径",
            approver_id: "第一审批人",
            approve_time: "第一审批时间",
            cc: "抄送",
            remark: "备注",
            status: "状态",
            approver2_id: "第二审批人",
            approve2_time: "第二审批时间",
            reject_reason: "拒绝原因",
            submit_time: "申请日期",
            time_frame: "时段",
            time_unit: "时间单位(日)",
            
        },
        field: {
            id_lea: "ID",
            id_emp: "员工",
            id_lea_typ: "请假类型",
            leave_type: "请假类型",
            start_time: "开始时间",
            end_time: "结束时间",
            duration: "时长（天）",
            reason: "原因",
            picture_url: "图片路径",
            approver_id: "第一审批人",
            approve_time: "第一审批时间",
            cc: "抄送",
            remark: "备注",
            status: "状态",
            approver2_id: "第二审批人",
            approve2_time: "第二审批时间",
            reject_reason: "拒绝原因",
            submit_time: "申请日期",
            time_frame: "时段",
            time_unit: "时间单位(日)",
        },
        validate: {
            required: {
                id_lea: "请填写ID",
                id_emp: "请填写员工",
                id_lea_typ: "请填写请假类型",
                leave_type: "请填写请假类型",
                start_time: "请填写开始时间",
                end_time: "请填写结束时间",
                duration: "请填写时长",
                reason: "请填写原因",
                picture_url: "请填写图片路径",
                approver_id: "请填写第一审批人",
                approve_time: "请填写第一审批时间",
                cc: "请填写抄送",
                remark: "请填写备注",
                status: "请填写状态",
                approver2_id: "请填写第二审批人",
                approve2_time: "请填写第二审批时间",
                reject_reason: "请填写拒绝原因",
                submit_time: "请填写申请日期",
                time_frame: "请填写时段",
                time_unit: "请填写时间单位",
            }
        },
    },
    leaveType:{
        name: {
            detailTitle: "LeaveType Detail",
            editTitle: "Edit LeaveType"
        },
        table: {
            id_lea_typ: "ID",
            leave_type_name: "名称",
            payroll_required: "需计算工资",
            leave_type_name_en: "名称（英文）",
        },
        field: {
            id_lea_typ: "ID",
            leave_type_name: "名称",
            payroll_required: "需计算工资",
            leave_type_name_en: "名称（英文）",
        },
        validate: {
            required: {
                id_lea_typ: "请填写ID",
                leave_type_name: "请填写名称",
                payroll_required: "请填写需计算工资",
                leave_type_name_en: "请填写名称（英文）",
            }
        },
    },
    mpfSetting:{
        name: {
            detailTitle: "MpfSetting Detail",
            editTitle: "Edit MpfSetting"
        },
        table: {
            id_mpf_set: "ID",
            min: "开始计算金额（不含）",
            max: "结束计算金额（含）",
            percentage: "强积金百分比",
            fix_amount: "固定金额",
            caculate_method: "计算方式",
        },
        field: {
            id_mpf_set: "ID",
            min: "开始计算金额（不含）",
            max: "结束计算金额（含）",
            percentage: "强积金百分比",
            fix_amount: "固定金额",
            caculate_method: "计算方式",
        },
        validate: {
            required: {
                id_mpf_set: "请填写ID",
                min: "请填写开始计算金额（不含）",
                max: "请填写结束计算金额（含）",
                percentage: "请填写强积金百分比",
                fix_amount: "请填写固定金额",
                caculate_method: "计算方式",
            }
        },
    },
    overtimeApplication:{
        name: {
            detailTitle: "OvertimeApplication Detail",
            editTitle: "Edit OvertimeApplication",
            search: "名称",
            approve: "审批",
            historyApplication: "历史申请",
            are_you_sure_pass: "确认通过加班申请"
        },
        table: {
            id_ove_app: "ID",
            id_emp: "员工",
            start_time: "开始时间",
            end_time: "结束时间",
            duration: "时长（小时）",
            approver_id: "审批人",
            approve_time: "审批时间",
            reason: "原因",
            status:"状态",
            submit_time: "申请日期",
            time_frame: "时段",
            time_unit: "时间单位（小时）",
            status: "状态",
            reject_reason: "拒绝原因",
            approver2_id: "第二审批人",
            approver2_time: "第二审批时间",
            time_remark: "时段",
        },
        field: {
            id_ove_app: "ID",
            id_emp: "员工",
            start_time: "开始时间",
            end_time: "结束时间",
            duration: "时长（小时）",
            approver_id: "审批人",
            approve_time: "审批时间",
            reason: "原因",
            submit_time: "申请日期",
            time_frame: "时段",
            time_unit: "时间单位（小时）",
            status: "状态",
            reject_reason: "拒绝原因",
            approver2_id: "第二审批人",
            approver2_time: "第二审批时间",
            time_remark: "时段",
        },
        validate: {
            required: {
                id_ove_app: "请填写ID",
                id_emp: "请填写员工",
                start_time: "请填写开始时间",
                end_time: "请填写结束时间",
                duration: "请填写时长",
                approver_id: "请填写审批人",
                approve_time: "请填写审批时间",
                reason: "请填写原因",
                submit_time: "请填写申请日期",
                time_frame: "请填写时段",
                time_unit: "请填写时间单位",
                status: "请填写状态",
                reject_reason: "请填写拒绝原因",
                approver2_id: "请填写第二审批人",
                approver2_time: "请填写第二审批时间",
                time_remark: "请填写时段",
            }
        },
    },
    remainingVacation:{
        name: {
            detailTitle: "RemainingVacation Detail",
            editTitle: "Edit RemainingVacation",
            search: "名称",
            year: "年份",
            lessThan: "年假小于",
            moreThan: "年假大于",
            prediction: "预算年假"
        },
        table: {
            id_rem_vac: "ID",
            id_emp: "员工",
            annual_leave_days: "总年假",
            maternity_leave_days: "总产假",
            paternity_leave_days: "总侍产假",
            remaining_annual_leave_days: "剩余年假",
            remaining_maternity_leave_days: "剩余产假",
            remaining_paternity_leave_days: "剩余侍产假",
            year: "所属年份",
            remark: "备注",
            remaining_sick_leave_days: "剩余病假",
            vacation_type:"Vacation Type",
            vacation:"應得假期（截至假期年末）",
            used_vacation:"已用假期（已獲批之未放假期）",
            remaining_vacation:"假期結餘（截至假期年末）",
            real_annual_leave_days: "当前实际剩余年假",
            remaining_birthday_leave_days: "剩余生日假",
            total_maternity:"总产假",
            total_paternity:"总侍产假",
            total_birthday:"总生日假",
        },
        field: {
            id_rem_vac: "ID",
            id_emp: "员工",
            annual_leave_days: "总年假",
            maternity_leave_days: "总产假",
            paternity_leave_days: "总侍产假",
            remaining_annual_leave_days: "剩余年假",
            remaining_maternity_leave_days: "剩余产假",
            remaining_paternity_leave_days: "剩余侍产假",
            year: "所属年份",
            remark: "备注",
            remaining_sick_leave_days: "剩余病假",
            sick_leave_days: "总病假",
            real_annual_leave_days: "当前实际剩余年假",
            remaining_birthday_leave_days: "剩余生日假",
        },
        validate: {
            required: {
                id_rem_vac: "请填写ID",
                id_emp: "请填写员工",
                annual_leave_days: "请填写总年假",
                maternity_leave_days: "请填写总产假",
                paternity_leave_days: "请填写总侍产假",
                remaining_annual_leave_days: "请填写剩余年假",
                remaining_maternity_leave_days: "请填写剩余产假",
                remaining_paternity_leave_days: "请填写剩余侍产假",
                year: "请填写所属年份",
                remark: "请填写备注",
                remaining_sick_leave_days: "请填写剩余病假",
                real_annual_leave_days: "请填写当前实际剩余年假",
                remaining_birthday_leave_days: "请填写剩余生日假",
            }
        },
    },
    report:{
        name: {
            detailTitle: "Report Detail",
            editTitle: "Edit Report",
            month: "月份",
            month_placeholder: "选择月份",
            generalSalary: "生成工资记录",
            batchDelete: "批量删除",
            totalSalary: "工资总计",
            totalMpf: "雇员强积金总计",
            totalRealSalary: "实发工资",
            search: "名称",
            batchConfirm: "批量确认",
            are_you_sure_batch_delete: "确认要批量删除吗",
            are_you_sure_confirm: "是否需要确认该记录",
            are_you_sure_batch_confirm: "是否需要批量确认所有记录",
            downSlip: "下载粮单",
            down_payroll_report: "下载薪金报表",
            sendSlip: "发送粮单",
            down_yearly_payroll_report:"下载年报"
        },
        table: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间",
            bonus: "花红",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "雇员强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
            status: "状态",
            need_attendance_days: "应出勤天数",
            need_mpf_days: "应计算MPF天数",
            mpf_start_date: "MPF开始计算日期",
            join_date: "入职日期",
            pay_date: "支付日期",
            employer_mpf: "雇主强积金",
        },
        field: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间",
            bonus: "花红",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "雇员强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
            status: "状态",
            need_attendance_days: "应出勤天数",
            mpf_start_date: "MPF开始计算日期",
            employer_mpf: "雇主强积金",
        },
        validate: {
            required: {
                id_rep: "请填写ID",
                id_emp: "请填写员工",
                month: "请选择月份",
                base_salary: "请填写基础工资",
                allowance: "请填写津贴",
                car_subsidy: "请填写车补",
                house_subsidy: "请填写房补",
                other_subsidy: "请填写其他补贴",
                commission: "请填写佣金",
                overtime_times: "请填写加班次数",
                overtime_pay: "请填写加班工资",
                overtime: "请填写加班时间",
                bonus: "请填写花红",
                compensatory_leave_time: "请填写调休时间",
                total_use_annual_leave: "请填写本年已用年假",
                total_annual_leave: "请填写本年总年假",
                use_annual_leave: "请填写本月总使用年假",
                remark: "请填写备注",
                leave_time: "请填写本月请假时间",
                absence_days: "请填写缺勤天数",
                create_time: "请填写创建时间",
                mpf: "请填写强积金",
                other_deductions: "请填写其他扣除",
                total_salary: "请填写实发金额",
                attendance_days: "请填写出勤天数",
                rest_days: "请填写休息天数",
                work_hours: "请填写工作时长",
                late_time: "请填写迟到时间",
                late_times: "请填写迟到次数",
                serious_late_times: "请填写严重迟到次数",
                serious_late_time: "请填写严重迟到时长",
                absenteeism_times: "请填写旷工次数",
                early_leave_time: "请填写早退时间",
                early_leave_times: "请填写早退次数",
                no_check_in_times: "请填写上班缺卡次数",
                no_check_out_times: "请填写下班缺卡次数",
                status: "请填写状态",
                status: "请填写状态",
                need_attendance_days: "请填写应出勤天数",
                employer_mpf: "请填写雇主强积金",
            }
        },
    },
    role:{
        name: {
            detailTitle: "Role Detail",
            editTitle: "Edit Role"
        },
        table: {
            id_rol: "ID",
            id_dep: "所属部门",
            name: "职位",
            remark: "备注",
                        approver_id: "第一审批人",
            approver2_id: "第二审批人",
        },
        field: {
            id_rol: "ID",
            id_dep: "所属部门",
            name: "职位",
            remark: "备注",
            authorityList: "权限",
                        approver_id: "第一审批人",
            approver2_id: "第二审批人",
        },
        validate: {
            required: {
                id_rol: "请填写ID",
                id_dep: "请填写所属部门",
                name: "请填写职位",
                remark: "请填写备注",
                authorityList: "请选择权限",
                                approver_id: "请填写第一审批人",
                approver2_id: "请填写第二审批人",
            }
        },
    },
    salaryInformation:{
        name: {
            detailTitle: "SalaryInformation Detail",
            editTitle: "Edit SalaryInformation",
            search: "名称"
        },
        table: {
            id_sal_inf: "ID",
            id_emp: "员工",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            saturday_overtime_multiples: "周六加班倍数",
            sunday_overtime_multiples: "周日加班倍数",
            holiday_overtime_multiples: "节假日加班倍数",
            normal_overtime_multiples: "平日加班倍数",
            remark: "备注",
            create_time: "创建时间",
            expire_time: "失效时间",
            status: "状态",
            commission: "佣金",
            bonus: "花红",
        },
        field: {
            id_sal_inf: "ID",
            id_emp: "员工",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            saturday_overtime_multiples: "周六加班倍数",
            sunday_overtime_multiples: "周日加班倍数",
            holiday_overtime_multiples: "节假日加班倍数",
            normal_overtime_multiples: "平日加班倍数",
            remark: "备注",
            create_time: "创建时间",
            expire_time: "失效时间",
            status: "状态",
            commission: "佣金",
            bonus: "花红",
        },
        validate: {
            required: {
                id_sal_inf: "请填写ID",
                id_emp: "请填写员工",
                base_salary: "请填写基础工资",
                allowance: "请填写津贴",
                car_subsidy: "请填写车补",
                house_subsidy: "请填写房补",
                saturday_overtime_multiples: "请填写周六加班倍数",
                sunday_overtime_multiples: "请填写周日加班倍数",
                holiday_overtime_multiples: "请填写节假日加班倍数",
                normal_overtime_multiples: "请填写平日加班倍数",
                remark: "请填写备注",
                create_time: "请填写创建时间",
                expire_time: "请填写失效时间",
                commission: "请填写佣金",
                bonus: "请填写花红",
            }
        },
    },
    generalSalary:{
        name: {
            title: "生成工资",
            month: "月份",
            month_placeholder: "选择月份",
            generalSalary: "生成"
        },
        table: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间（分钟）",
            bonus: "奖金",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            total_wages: "总工资",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
        },
        field: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间(分钟)",
            bonus: "奖金",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            total_wages: "总工资",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
        },
        validate: {
            required: {
                id_rep: "请填写ID",
                id_emp: "请填写员工",
                month: "请填写月份",
                base_salary: "请填写基础工资",
                allowance: "请填写津贴",
                car_subsidy: "请填写车补",
                house_subsidy: "请填写房补",
                other_subsidy: "请填写其他补贴",
                commission: "请填写佣金",
                overtime_times: "请填写加班次数",
                overtime_pay: "请填写加班工资",
                overtime: "请填写加班时间",
                bonus: "请填写奖金",
                compensatory_leave_time: "请填写调休时间",
                total_use_annual_leave: "请填写本年已用年假",
                total_annual_leave: "请填写本年总年假",
                use_annual_leave: "请填写本月总使用年假",
                remark: "请填写备注",
                leave_time: "请填写本月请假时间",
                absence_days: "请填写缺勤天数",
                create_time: "请填写创建时间",
                mpf: "请填写强积金",
                other_deductions: "请填写其他扣除",
                total_salary: "请填写实发金额",
                total_wages: "请填写总工资",
                attendance_days: "请填写出勤天数",
                rest_days: "请填写休息天数",
                work_hours: "请填写工作时长",
                late_time: "请填写迟到时间",
                late_times: "请填写迟到次数",
                serious_late_times: "请填写严重迟到次数",
                serious_late_time: "请填写严重迟到时长",
                absenteeism_times: "请填写旷工次数",
                early_leave_time: "请填写早退时间",
                early_leave_times: "请填写早退次数",
                no_check_in_times: "请填写上班缺卡次数",
                no_check_out_times: "请填写下班缺卡次数",
            }
        },
    },
    leaveApprove:{
        name: {
            title: "请假审批",
            employeeInfo: "员工信息",
            remainingVacation: "剩余年假",
            historyLeave: "历史请假记录",
            pass: "通过",
            reject: "拒绝",
            are_you_sure_pass: "确认通过该请假申请",
            reject_title: "拒绝请假"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    overtimeApplicationApprove:{
        name: {
            title: "加班申请审批",
            overtimeApplication: "加班申请信息",
            historyApplication: "历史申请",
            reject_title: "拒绝加班申请"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    gccsSetUser:{
        name: {
            title: "写入考勤机",
        },
        table: {
            
        },
        field: {
            device: "设备",
            user_privilege: "考勤机权限",
        },
        validate: {
            required: {
                device: "请选择设备",
                user_privilege: "请选择权限",
            }
        },
    },
    downloadLeaveReport:{
        name: {
            title: "请假报告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    newJoinReport:{
        name: {
            title: "入职报告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    termReport:{
        name: {
            title: "离职报告",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    reportConfirm:{
        name: {
            title: "确认",
        },
        table: {
            
        },
        field: {
            pay_date:"支付日期"
        },
        validate: {
            required: {
                pay_date: "请输入支付日期",
            }
        },
    },
    settings:{
        name: {
            detailTitle: "Settings Detail",
            editTitle: "Edit Settings"
        },
        table: {
            id_set: "ID",
            name: "名称",
            content: "内容",
        },
        field: {
            id_set: "ID",
            name: "名称",
            content: "内容",
        },
        validate: {
            required: {
                id_set: "请填写ID",
                name: "请填写名称",
                content: "请填写内容",
            }
        },
    },
    config:{
        name: {
            email_setting: "邮箱设定",
        },
        table: {
        },
        field: {
            host: "域名",
            username: "用户名",
            password: "密码",
            sender: "发送者邮箱",
        },
        validate: {
            required: {
                host: "请输入域名",
                username: "请输入用户名",
                password: "请输入密码",
                sender: "请输入发送者邮箱",
            }
        },
    },
    roleLogs:{
        name: {
            detailTitle: "RoleLogs Detail",
            editTitle: "Edit RoleLogs"
        },
        table: {
            id_rol_log: "ID",
            id_emp: "员工",
            create_time: "创建时间",
            position_name: "职位名称",
        },
        field: {
            id_rol_log: "ID",
            id_emp: "员工",
            create_time: "创建时间",
            position_name: "职位名称",
        },
        validate: {
            required: {
                id_rol_log: "请填写ID",
                id_emp: "请填写员工",
                create_time: "请填写创建时间",
                position_name: "请填写职位名称",
            }
        },
    },
    prediction:{
      name: {
          title: "预算年假",
          date: "日期"
      },
      table: {
          
      },
      field: {
          
      },
      validate: {
          required: {
              date: "请输入日期",
          }
      },
    },
}

export default zhCN